import React, { useState } from "react";
import logo from "./record3.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{ fontWeight: "bold" }}>
          💍 Tom and Lauren's Wedding Playlist Site 💍
        </p>
        <p>
          We want you to help us bring some vibes from across the world to our
          Kiwi wedding in March. Tap the playlist and throw in your bangers!
        </p>
        <p>We love you, wherever you are xx</p>
      </header>
      <div className="pl-wrapper">
        <div
          className="pl-item"
          onClick={() =>
            window.open(
              "https://open.spotify.com/playlist/0TOTGrNS1xltGJUIEWLO0n?si=c9d66df9ca3c4b1c&pt=248494446aee26bc32bd6822fd912c37"
            )
          }
        >
          <img src={logo} className="App-logo" alt="logo" />
          <p>open spotify playlist</p>
        </div>
      </div>
    </div>
  );
}

export default App;
